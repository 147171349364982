<template>
    <div id="add-contact">

        <h2 is="sui-header" class="dividing">Add Contact Information</h2>
        <sui-form :loading="loading" :error="errorTitle !== ''" :success="successTitle !== ''">
            <success-message :title="successTitle" :reasons="successList"></success-message>
            <error-message :title="errorTitle" :reasons="errorList"></error-message>
            <contact-form :contact="contact"></contact-form>
        </sui-form>

        <sui-divider></sui-divider>
        <sui-button type="submit" color="blue" @click.prevent="save">Create Location</sui-button>
        <sui-button floated="right" @click.prevent="cancel">Cancel</sui-button>

    </div>
</template>

<script>
    import axios from "axios";

    import ContactForm from "@/views/dashboard/contact/ContactForm";
    import ErrorMessage from "@/components/ErrorMessage";
    import SuccessMessage from "@/components/SuccessMessage";

    export default {
        name: "ContactAdd",
        components: {ContactForm, ErrorMessage, SuccessMessage},
        data: function () {
            let locations = ['CNTower', 'Behan', 'Vector']
            const contact_type_options = ['All', 'Public Service', 'Team', 'Elevator Service', 'Building Plan']

            return {
                selectedLocation: null,
                locations: locations.map((i) => {
                    return {
                        value: i,
                        text: i,
                    }
                }),
                selectedContactType: null,
                contactType: contact_type_options.map((i) => {
                    return {
                        text: i,
                        value: i
                    }
                }),

                contact: {
                    location: "",
                    contactType: "",
                    title: "",
                    contactNumber: "",

                    description: "",

                    errorTitle: false,
                    errorLocation: false,
                    errorContactType: false,
                },
                loading: false,

                errorTitle: "",
                errorList: [],

                successTitle: "",
                successList: [],
            }
        },
        methods: {
            cancel: function () {
                this.$router.push('/dashboard/contact')
            },
            save: async function () {
                this.contact.errorTitle = this.contact.title.trim() === "";
                this.contact.errorLocation = this.contact.location.trim() === "";
                this.contact.errorContactType = this.contact.contactType.trim() === "";

                if (!(this.contact.errorTitle || this.contact.errorLocation || this.contact.errorContactType)) {
                    axios({
                        method: "POST",
                        url: "/api/contact/create",
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                        data: {
                            token: this.$cookies.get('token'),
                            tokenId: this.$cookies.get('tokenId'),
                            contactType: this.contact.contactType,
                            title: this.contact.title,
                            contactNumber: this.contact.contactNumber,
                            location: this.contact.location,
                            description: this.contact.description
                        }
                    }).then((result) => {
                        if (result.data.rowId) {
                            this.successTitle = "Created new contact!"
                            this.errorTitle = "";
                            this.errorList = [];
                        } else {
                            this.errorTitle = "Cannot create new contact!";
                            this.errorList = [];
                            this.errorList.push("Network connection issue.");
                        }

                    }).catch((err) => {
                        console.log(err);
                        this.errorTitle = "Cannot create new contact";
                        this.errorList = [];
                        this.errorList.push("Network connection issue.");
                    }).finally(() => {
                        this.contact.errorTitle = false;
                        this.contact.errorLocation = false;
                        this.contact.errorContactType = false;
                    })
                }
            }
        }

    }
</script>

<style scoped>

</style>
